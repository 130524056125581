import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "category-table", fluid: "", tag: "section" } },
    [_c("ManagerPartnerTable")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }