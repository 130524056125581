import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "category-table", fluid: "", tag: "section" } },
    [
      !_vm.loading
        ? _c(
            "base-material-card",
            {
              staticClass: "px-5 py-3 mb-5",
              attrs: { icon: "mdi-domain", inline: "", color: "secondary" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "after-heading",
                    fn: function () {
                      return [
                        _c("h1", { staticClass: "h5" }, [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$t("manager_companies"))),
                          ]),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2123760429
              ),
            },
            [
              _c(
                "div",
                { staticClass: "button-top-table-container" },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", rounded: "", fab: "" },
                      on: { click: _vm.addManagerCompany },
                    },
                    [
                      _c(VIcon, { attrs: { large: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "secondary", rounded: "", fab: "" },
                      on: {
                        click: function () {
                          return (_vm.dialogSelectedDefaultManager = true)
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { large: "" } }, [
                        _vm._v("mdi-cog"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(VSimpleTable, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("name")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("edit")))]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.managerPartnerData, function (manager, i) {
                    return _c("tr", { key: i }, [
                      _c("td", [_vm._v(_vm._s(manager.name))]),
                      _c(
                        "td",
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "px-2 ml-1 secondary",
                              attrs: { "min-width": "0", small: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEditManagerCompany(manager)
                                },
                              },
                            },
                            [
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("mdi-pencil"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  }),
                  0
                ),
              ]),
              _c(VPagination, {
                attrs: { color: "primary", length: _vm.pageCount },
                on: { input: _vm.changePage },
                model: {
                  value: _vm.page,
                  callback: function ($$v) {
                    _vm.page = $$v
                  },
                  expression: "page",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "box-loading" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "70", color: "primary" },
              }),
            ],
            1
          ),
      _vm.dialogSelectedDefaultManager
        ? _c(
            VDialog,
            {
              model: {
                value: _vm.dialogSelectedDefaultManager,
                callback: function ($$v) {
                  _vm.dialogSelectedDefaultManager = $$v
                },
                expression: "dialogSelectedDefaultManager",
              },
            },
            [
              _c("ManagerPartnerSettings", {
                on: {
                  close: function () {
                    return (_vm.dialogSelectedDefaultManager = false)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }